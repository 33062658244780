<template>

  <v-card outlined color="transparent">

      <v-list density="compact" flat class="mx-auto overflow-x-hidden overflow-y-auto"  max-height=70vh>

        <v-list-item-group v-model="store.selected" multiple active-class="border">
     
          <v-list-item class="notSelected mb-1 useFont" v-for="(item, index) in shortList" :key="index" :value="item.Id">

            <template v-slot:default="{ active }">
              
              <v-list-item-action>
     
                <v-checkbox
                         :input-value =  "active"
                         :on-icon     =  "'mdi-check-circle'"
                         color        =  "(#00A6FB)"
                         >

                </v-checkbox>
     
              </v-list-item-action>

              <v-list-item-content>

                 <v-list-item-title class="jobTitle">
                   <strong>{{item.JobTitle}}</strong>
                 </v-list-item-title>
           
                 <div class="mt-2 d-flex">
                   
                  <table class="fixed">
                    <tr>
                        <td class="myText">
                          <span class="mr-2">Location:</span>
                          <span class="txtResult">{{ item.Location }}</span>
                        </td>
                    </tr>
                    <tr>
                        <td class="myText">
                        <span class="mr-2">Service:</span>
                        <span class="txtResult">{{ item.Service }}</span>
                      </td>
                    </tr>
                    <tr>
                        <td class="myText">
                         <span class="mr-2">Year Qualified:</span>
                         <span class="txtResult">{{ item.YearQualified }}</span>
                        </td>
                    </tr>

                    <tr>
                        <td class="myText">
                          <span class="mr-2">Seeking:</span>
                          <span class="txtResult">{{ item.Seeking }}</span>
                        </td>
                    </tr>

                  </table>

                  </div>

                 <div class="mt-2">
                    <span class="mySkillsText mySkillsLabel">Experience / Expertise:</span>
                 </div>
                
              
                 <v-list-item-subtitle class="text-wrap mySkillsText">
                   {{ expandSkills ( item.Skills) }}
                </v-list-item-subtitle>
            
              </v-list-item-content>

            </template>

          </v-list-item>

        </v-list-item-group>
   
      </v-list>

  </v-card>

</template>


<script>
     
  import { store }  from '@/store/store'

  export default {
    name        : 'plShortlist',
    computed    : {

      shortList() {
         const  res =  (store.shortList) ? store.shortList : [];
         return res;
      },

      showShortList() {
        const  res = ( store.shortList.length > 0 ) ? true : false;
        return res;
      }
      
    },

    methods     : {
                 expandSkills( skills ) {
                   return skills.replaceAll(',', ', ');
                 }
    },
   
    data: () => ({ store }),

  }


</script>


<style>

.border {
  border        : 1px dashed #00A6FB !important;
}

.notSelected {
  border        : 1px solid  #00A6FB;
  border-radius : 5px;
  margin        : auto;
  width         : 98%;
  box-shadow    : 0 3px 10px rgb(0 0 0 / 0.2) !important;
}

* /deep/ .v-list-item__subtitle {
  white-space: normal;
}

div.v-input__slot > div > i { 
  font-size : 42px !important;
  color     : rgb(0, 166, 251) !important;
}


table.fixed {
      table-layout: fixed;
      width: 100%;
    }
table.fixed td {
      overflow: hidden;
    }


.jobTitle {
  font-size : 18px        !important;
  color     : black   !important;
  }   


.myText {
  font-size : 16px        !important;
  color     : rgb(0, 166, 251) !important;
}

.myTextLabel {
  color     : rgb(0, 166, 251) !important;
}


.mySkillsLabel {
  color     : rgb(0, 166, 251) !important;
}

.mySkillsText {
   font-size : 16px        !important;
}

.txtResult {
  font-size : 16px        !important;
  color     : black   !important;
}

</style>