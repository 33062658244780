<template>

      <v-dialog   v-model    = "store.enquiry.showDialog"
                  max-width  = "480px"
                  persistent
      >

      <v-card elevation="8">

          <div v-if='enquiryProcessing' class="d-flex align-center justify-space-around">
            
                <div>
                      <v-card-title     class="useFont">Enquiry processing</v-card-title>
                      <v-card-subtitle  class="useFont">Sending Email Request</v-card-subtitle>
                </div>

                <div>
                    <v-progress-circular  indeterminate color=blue></v-progress-circular>
                </div>
            
          </div>
 
          <div v-if = "store.enquiry.errorMessage">
                 <v-card-title class="useFont">Enquiry failed to send</v-card-title>
          </div>
 
          <div v-if = "store.enquiry.emailConfirmation">
                 <v-card-title class="useFont">Enquiry sent successfully</v-card-title>
          </div>
           
          <v-divider></v-divider>

          <v-card-text  v-if = "store.enquiry.emailConfirmation" class="useFont fontSize12" >
                      Confirmation:<br/> 
                      <span><strong>{{ store.enquiry.emailConfirmation}}</strong></span>
          </v-card-text>

          <v-card-text  v-if = "store.enquiry.errorMessage"     class="useFont fontSize12">
                       <span style="color:red">
                        ERROR : {{ store.enquiry.errorMessage}}
                      </span>
          </v-card-text >
                 
          <div v-if = "!enquiryProcessing"> 
              <v-divider></v-divider> 
              <v-card-actions>
                <v-spacer></v-spacer>
                   <v-btn 
                    color=blue
                    text
                    @click ="closeEnquiryDialog"
                  ><span class="useFont"><strong>Close</strong></span>
                  </v-btn>
              </v-card-actions>
          </div>

      </v-card>
            
      </v-dialog>

</template>


<script>

    import { store }          from '../../store/store'

    export default {

      name: 'EnquiryDialog',

      computed    : {

        enquiryProcessing() {
          return store.enquiry.processing;
        }
      },
      methods     : {

            closeEnquiryDialog() {

                  store.selected  = [];
                  store.shortList = [];
                  store.filter    = {
                                      skill      : '',
                                      jobTitle   : '',
                                      location   : '',
                                      service    : '',
                                   },
                  store.enquiry  = {
                                          emailConfirmation : null,
                                          errorMessage      : null,
                                          processing        : false,  
                                          showDialog        : false,
                                  };

                  this.$router.push( {name:'home'} )
            }
      },
      
      data () {
        return {  
            store,
        }
      },
  }

</script>