<template>

  <v-app >

    <v-snackbar v-model    = "store.errorSnackBar.display"
                :color     = "store.errorSnackBar.color"
                 outlined
                 elevation="24"
                 top 
                center 
        >
          {{ store.errorSnackBar.text }}
          <template v-slot:action="{ attrs }">
            <v-btn
              :color = "store.errorSnackBar.color"
              text
              v-bind="attrs"
              @click ="store.errorSnackBar.display = false"
            >
              Close
            </v-btn>
          </template>
    </v-snackbar>
    
     <v-snackbar bottom left :value="updateExists" :timeout="-1" color="primary">
      An update is available
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
     </v-snackbar>

     <aboutDialog     />
     <enquiryDialog   />
          
     <v-app-bar v-if="false" flat app> 
     </v-app-bar>
 
     <v-main>
          <pl-header />
            <v-container fluid>
             <router-view />
            </v-container>
           <plFooter />
     </v-main>

    
  </v-app>

</template>

<script>

import axios            from "axios";
import { store }        from  './store/store'
import { apiResource }  from  './store/apiResource'

import update           from  '@/mixins/update'

import aboutDialog      from '@/components/Dialogs/aboutDialog.vue';
import enquiryDialog    from '@/components/Dialogs/enquiryDialog.vue';


import plFooter         from '@/components/Footer/plFooter.vue';
import plHeader         from '@/components/Header/plHeader.vue';


export default {
  
  name                        : 'App',
  components                  : { plFooter, plHeader, aboutDialog,enquiryDialog },
 
  mounted() {

      this.store.screenSize.width  = document.documentElement.clientWidth;
      this.store.screenSize.height = document.documentElement.clientHeight;
      window.addEventListener('resize', this.getDimensions);

      this.updateCandidates();
      this.timedUpdateApp();
      
  },
  
    unmounted() {
        window.removeEventListener('resize', this.getDimensions);
    },

    watch      : {},
    computed   : {},

  methods : {
 
    updateCandidates : async function(){
    
    const  url =  apiResource.getResourceUrl("candidates");
    axios.get( url )
    .then( response => {

          if ( response.data ) {
               this.store.candidates = response.data;
          }

    }).catch((error) => { 
      
        if (error.response) {        // status code out of the range of 2xx

          const errorMessage            =   `${error.response.data.message} Status = ${error.response.status}`;
          this.store.errorSnackBar.color   = "error";
          this.store.errorSnackBar.text    = errorMessage;
          this.store.errorSnackBar.display = true;
          console.log( errorMessage );


        } else if (error.request) {  // The request was made but no response was received
           console.log(error.request);
        } else {                     // Error on setting up the request
           console.log('Error', error.message);
        }
    });
     
    },

    timedUpdateApp : async function(){

            setTimeout(() => { this.timedUpdateApp(); }, 1000*60)
 
            var registration    = await navigator.serviceWorker.getRegistration();
            if ( registration ){
               var updated      = await registration.update();
               return updated;
            }
    },

    getDimensions() {
         this.store.screenSize.width  = document.documentElement.clientWidth;
         this.store.screenSize.height = document.documentElement.clientHeight;
    },

  },

  data: () => ({
    store
  }), 

  mixins: [update],

};

</script>

<style>

.container--fluid { 
              padding: 0px !important;
              background-color: #FAFAFA !important;
}

.v-card { 
            border-radius: 0px !important;
            box-shadow:    0px !important;
}

.plHomeMessage{
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    font-weight: 700 !important;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 1.3em;
    letter-spacing: 0px;
    text-align: center;
}

.plHomeFilterDiv {
  border-radius: 15px !important;
  background-color: #FAFAFA !important;
}

.plHomeFilterDiv form {
  padding: 18px !important;  
  background-color: #FAFAFA !important;
}

.plHomeFilterDivFormButton{
  display: block;
  font-family: 'Atkinson Hyperlegible', sans-serif;
  font-size: 18px !important;
  font-weight: 400;
}

.plHeaderLogo {
    display     : flex;
    margin-right: auto;
    margin-left : auto;
    position    : relative;
    width       : 65%;
    padding     : 10px
}

.bottomNavBar{
  padding-top: 5px !important;
}

.bottomNavButton{
  background-color: transparent !important;
}

.removeBoxShadow {
  box-shadow: none !important;
}

.up25 {
   margin-top: -25px !important;
}

.viewTitle {
  font-family: 'Roboto';
  font-size: 20px;
}

.bottomNavBar {
  padding-top: 5px !important;
}

.useFont {
  font-family: "Atkinson Hyperlegible", sans-serif !important;
}

.fontSize16 {
  font-size: 16px !important;
}

.fontSize14 {
  font-size: 14px !important;
}

.fontSize12 {
  font-size: 12px !important;
}

</style>